body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.user-confirmation-email-modal {
  --height: 200px;
}

.user-confirmation-email-modal div.rodal-dialog {
  padding: 0;
  width: 100% !important;
  height: var(--height) !important;
}

.user-confirmation-email-modal iframe {
  width: 100%;
  height: var(--height);
}

body .delete-button {
  background-color: #B33A3A;
  color: white;
  margin-right: 10px;
}

body .delete-button:hover {
  background-color: #951D24;
}

body .snackbar-error {
  background-color: #B33A3A;
}


.slider-label,
.MuiFormControlLabel-root {
  width: 100%
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #2c387e !important;
}

.platformListItem {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 0
}

.platformIcon {
  width: 40px;
  margin-right: 10px;
}

.rangeslider-horizontal .rangeslider__handle:after {
  top: 7px !important;
  left: 7px !important;
}